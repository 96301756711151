import { Component } from 'react';
import React from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Footer.css'


class Footer extends Component {
    render() {
        return (
            <footer className="Footer">
                <div className="black-line">
                    <i className="fas fa-angle-up fa-lg" onClick={()=>window.scrollTo(0,0)}></i>
                </div>
                <div className="footer-links">
                    <a className="a-color" href="https://www.instagram.com/ilya_yzor">Instagram</a>
                    <a className="a-color" href="https://vk.com/ilya_yzor">VK</a>
                    <a className="a-color" href="https://www.facebook.com/ilya.yzor">Facebook</a>
                </div>
                <div className="underlined">
                    <NavLink tag={Link} className="a-color small" to="/TermsOfUse">Terms of use</NavLink>
                    <NavLink tag={Link} className="a-color small" to="/PrivacyPolicy">Privacy Policy</NavLink>
                </div>
                <div className="ilya-yzor">
                    <p className="a-color">@ ILYA YZOR {new Date().getFullYear()}</p>
                </div>
                
            </footer>
        );
    }
}

export default Footer;