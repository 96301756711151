import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home/Home';
import About from './components/About/About';
import './custom.css'
import Contact from './components/Contact/Contact';
import TermsOfUse from './components/TermsOfUse/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />

        <Route path='/about' component={About} />

        <Route path='/contact' component={Contact} />
        <Route path='/TermsOfUse' component={TermsOfUse} />
        <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
        

    </Layout>
);
