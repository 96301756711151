import ImageGallery from 'react-image-gallery';
import * as React from 'react';
import "./modalGallery.css"

/*const images = [
    {
        original: 'https://picsum.photos/id/1018/1000/600/',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1015/1000/600/',
        thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1019/1000/600/',
        thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
];*/

class ModalGallery extends React.Component<
{ 
    images: { original: string; thumbnail: string;}[]; 
    thumbnailPosition: "right" | "left" | "bottom" | "top" | undefined; 
    showThumbnails: boolean | undefined; 
    showPlayButton: boolean | undefined; 
    showFullscreenButton: boolean | undefined;
    onClick : ()=>void | undefined
}>   
{ 
    render() {
        return <ImageGallery 
            items={this.props.images} 
            thumbnailPosition={this.props.thumbnailPosition} 
            showThumbnails={this.props.showThumbnails} 
            showPlayButton={this.props.showPlayButton} 
            showFullscreenButton={this.props.showFullscreenButton}
            onClick={this.props.onClick}
            lazyLoad ={true}
            />;
    }
}
export default ModalGallery