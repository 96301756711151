import * as React from 'react';
import './SelectedProjectsList.css'
import {SelectedProjectsListData} from "./SelectedProjectsListData"


type MyProps = { };
type MyState = { SelectedProjectsListData:{Year:string | number, Description:{Project:string}[] }[] };

class SelectedProjectsList extends React.Component<MyProps, MyState> 
{
    constructor(props:any) {
        super(props);
        this.state=
            { 
                SelectedProjectsListData
            }
      }
    render() 
    {
        return(<div className="SelectedProjects">
            <h1>Selected Projects</h1>
            <hr/>
             {
                this.state.SelectedProjectsListData.map((x, index) =>
                    <div key={index}>
                        <h3>{x.Year}</h3>
                        {
                            x.Description.map((p, indexProject) =>
                            <p key={indexProject}>{p.Project}</p>
                            )
                        }
                    </div>
                    )
             }
             </div>
        )
    
    }
}

export default SelectedProjectsList;