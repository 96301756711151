import wiesbadenLogo from '../../../img/Logos/wiesbadenLogo.jpg';
import MuseumOfVictoryLogo from '../../../img/Logos/MuseumOfVictoryLogo.jpg';
import MarPravdaLogo from '../../../img/Logos/MarPravdaLogo.jpg';
import GTRKLogo from '../../../img/Logos/GTRKLogo.jpg';
import allgemeineZeitungLogo from '../../../img/Logos/allgemeineZeitungLogo.jpg';
import MetrLogo from '../../../img/Logos/MetrLogo.jpg';
import RosMolodejLogo from '../../../img/Logos/RosMolodejLogo.jpg';


const wiesbaden = {
    Img: wiesbadenLogo,
    Alt: "Wiesbadener kurier"
}
const MuseumOfVictory = {
    Img: MuseumOfVictoryLogo,
    Alt: "Victory museum"
}
const MarPravda = {
    Img: MarPravdaLogo,
    Alt: "Marpravda"
}
const GTRK = {
    Img: GTRKLogo,
    Alt: "GKRK Mari El"
}
const allgemeineZeitung = {
    Img: allgemeineZeitungLogo,
    Alt: "Allgemeine Zeitung"
}
const Metr = {
    Img: MetrLogo,
    Alt: "Metr"
}
const RosMolodej = {
    Img: RosMolodejLogo,
    Alt: "Fadm"
}


export const PublisherListData = [
    {
        Source: wiesbaden,
        Description: "Graffitikünstler Manuel Gerullis dankt seinen Förderern mit einer Galerieführung.",
        URL:"https://www.wiesbadener-kurier.de/lokales/wiesbaden/stadtteile-wiesbaden/kastel/graffitikunstler-manuel-gerullis-dankt-seinen-forderern-mit-einer-galeriefuhrung_18295365"
    },
    {
        Source: MuseumOfVictory,
        Description: "Graffiti of A Military Nurse Appeared On The Wall Of The Stadium In Yoshkar-Ola.",
        URL:"https://cmvov.ru/?part=13&id_single=5552"
    },
    {
        Source: MarPravda,
        Description: "Graffiti Artist From Yoshkar-Ola Took Part In One Of The Largest Festivals In Russia.",
        URL:"https://www.marpravda.ru/news/religiya/yoshkar-olinskiy-graffitist-prinyal-uchastie-v-odnom-iz-krupneyshikh-festivaley-rossii-foto/"
    },
    {
        Source: GTRK,
        Description: "Changing: the facade art of the Fine Art Gallery is planned to be completed by the Republic Day.",
        URL:"http://www.gtrkmariel.ru/news/news-list/changing-the-painting-of-the-facade-of-the-museum-of-fine-arts-are-planning-to-complete-to-the-day-o/"
    },
    {
        Source: allgemeineZeitung,
        Description: "\„Meeting of Styles\" sorgt für Farbenrausch in Kastel.",
        URL:"https://www.allgemeine-zeitung.de/lokales/mainz/amoeneburg-kostheim-kastel/meeting-of-styles-sorgt-fur-farbenrausch-in-kastel_20229665#cxrecs_s"
    },
    {
        Source: Metr,
        Description: "The first \"geograffiti\" of a Snow leopard appeared In Yoshkar-Ola.",
        URL:"https://youtu.be/pRSJGt_sj6Y"
    },
    {
        Source: MarPravda,
        Description: "New Large-Scale Graffiti Appeared In Yoshkar-Ola.",
        URL:"https://www.marpravda.ru/news/gorod/novoe-masshtabnoe-graffiti-poyavilos-v-yoshkar-ole/"
    },
    {
        Source: GTRK,
        Description: "The project \"Urban Colors\” was finished in Yoshkar-Ola.",
        URL:"http://www.gtrkmariel.ru/news/news-list/v-yoshkar-ole-zavershilsya-proekt-kraski-goroda/"
    },
    {
        Source: RosMolodej,
        Description: "The Project Street Art School \“Urban Colors\”.",
        URL:"https://fadm.gov.ru/activity/success/762"
    }
]