import HardmodeOne_preview from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_main_preview.jpg'
import HardmodeOne from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_main.jpg'
import HardmodeOne_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_main_t.jpg'
import one1 from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_1.jpg'
import one1_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_1_t.jpg'
import one2 from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_2.jpg'
import one2_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_2_t.jpg'
import one3 from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_3.jpg'
import one3_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_3_t.jpg'
import one4 from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_4.jpg'
import one4_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_4_t.jpg'
import one5 from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_5.jpg'
import one5_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_5_t.jpg'
import one6 from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_6.jpg'
import one6_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_6_t.jpg'
import one7 from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_7.jpg'
import one7_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_7_t.jpg'
import one8 from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_8.jpg'
import one8_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_8_t.jpg'
import one9 from '../img/PROJECTS/1 Hardmode/WEB/Hardmode_9.jpg'
import one9_thumb from '../img/PROJECTS/1 Hardmode/THUMB/Hardmode_9_t.jpg'
import hm_neon_1 from '../img/PROJECTS/1 Hardmode/WEB/HardmodeNeon_1.jpg'
import hm_neon_1_thumb from '../img/PROJECTS/1 Hardmode/THUMB/HardmodeNeon_1_t.jpg'
import hm_neon_2 from '../img/PROJECTS/1 Hardmode/WEB/HardmodeNeon_2.jpg'
import hm_neon_2_thumb from '../img/PROJECTS/1 Hardmode/THUMB/HardmodeNeon_2_t.jpg'
import hm_neon_3 from '../img/PROJECTS/1 Hardmode/WEB/HardmodeNeon_3.jpg'
import hm_neon_3_thumb from '../img/PROJECTS/1 Hardmode/THUMB/HardmodeNeon_3_t.jpg'
import hm_neon_4 from '../img/PROJECTS/1 Hardmode/WEB/HardmodeNeon_4.jpg'
import hm_neon_4_thumb from '../img/PROJECTS/1 Hardmode/THUMB/HardmodeNeon_4_t.jpg'
import hm_neon_5 from '../img/PROJECTS/1 Hardmode/WEB/HardmodeNeon_5.jpg'
import hm_neon_5_thumb from '../img/PROJECTS/1 Hardmode/THUMB/HardmodeNeon_5_t.jpg'
import hm_neon_m from '../img/PROJECTS/1 Hardmode/WEB/HardmodeNeon_main.jpg'
import hm_neon_m_thumb from '../img/PROJECTS/1 Hardmode/THUMB/HardmodeNeon_main_t.jpg'


import BlackStarTwo_preview from '../img/PROJECTS/2 BlackStar13/WEB/BlackStar13_Main_preview.jpg'
import BlackStarTwo from '../img/PROJECTS/2 BlackStar13/WEB/BlackStar13_Main.jpg'
import BlackStarTwo_thumb from '../img/PROJECTS/2 BlackStar13/THUMB/BlackStar13_Main_t.jpg'
import two1 from '../img/PROJECTS/2 BlackStar13/WEB/BlackStar13_1.jpg'
import two1_thumb from '../img/PROJECTS/2 BlackStar13/THUMB/BlackStar13_1_t.jpg'
import two2 from '../img/PROJECTS/2 BlackStar13/WEB/BlackStar13_2.jpg'
import two2_thumb from '../img/PROJECTS/2 BlackStar13/THUMB/BlackStar13_2_t.jpg'
import two3 from '../img/PROJECTS/2 BlackStar13/WEB/BlackStar13_3.jpg'
import two3_thumb from '../img/PROJECTS/2 BlackStar13/THUMB/BlackStar13_3_t.jpg'
import two4 from '../img/PROJECTS/2 BlackStar13/WEB/BlackStar13_4.jpg'
import two4_thumb from '../img/PROJECTS/2 BlackStar13/THUMB/BlackStar13_4_t.jpg'
import two5 from '../img/PROJECTS/2 BlackStar13/WEB/BlackStar13_5.jpg'
import two5_thumb from '../img/PROJECTS/2 BlackStar13/THUMB/BlackStar13_5_t.jpg'
import two6 from '../img/PROJECTS/2 BlackStar13/WEB/BlackStar13_6.jpg'
import two6_thumb from '../img/PROJECTS/2 BlackStar13/THUMB/BlackStar13_6_t.jpg'
import two7 from '../img/PROJECTS/2 BlackStar13/WEB/BlackStar13_7.jpg'
import two7_thumb from '../img/PROJECTS/2 BlackStar13/THUMB/BlackStar13_7_t.jpg'

import CyberLogovoThree from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_Main.jpg'
import CyberLogovoThree_preview from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_Main_preview.jpg'
import CyberLogovoThree_thumb from '../img/PROJECTS/3 CyberLogovo2/THUMB/CyberLogovo_Main_t.jpg'
import three1 from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_1.jpg'
import three1_thumb from '../img/PROJECTS/3 CyberLogovo2/THUMB/CyberLogovo_1_t.jpg'
import three2 from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_2.jpg'
import three2_thumb from '../img/PROJECTS/3 CyberLogovo2/THUMB/CyberLogovo_2_t.jpg'
import three3 from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_3.jpg'
import three3_thumb from '../img/PROJECTS/3 CyberLogovo2/THUMB/CyberLogovo_3_t.jpg'
import three4 from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_4.jpg'
import three4_thumb from '../img/PROJECTS/3 CyberLogovo2/THUMB/CyberLogovo_4_t.jpg'
import three5 from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_5.jpg'
import three5_thumb from '../img/PROJECTS/3 CyberLogovo2/THUMB/CyberLogovo_5_t.jpg'
import three6 from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_6.jpg'
import three6_thumb from '../img/PROJECTS/3 CyberLogovo2/THUMB/CyberLogovo_6_t.jpg'
import three7 from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_7.jpg'
import three7_thumb from '../img/PROJECTS/3 CyberLogovo2/THUMB/CyberLogovo_7_t.jpg'
import three8 from '../img/PROJECTS/3 CyberLogovo2/WEB/CyberLogovo_8.jpg'
import three8_thumb from '../img/PROJECTS/3 CyberLogovo2/THUMB/CyberLogovo_8_t.jpg'

import HondaFour from '../img/PROJECTS/4 Honda_Kuzya/WEB/Honda_main.jpg'
import HondaFour_preview from '../img/PROJECTS/4 Honda_Kuzya/WEB/Honda_main_preview.jpg'
import HondaFour_thumb from '../img/PROJECTS/4 Honda_Kuzya/THUMB/Honda_main_t.jpg'
import four2 from '../img/PROJECTS/4 Honda_Kuzya/WEB/Honda_2.jpg'
import four2_thumb from '../img/PROJECTS/4 Honda_Kuzya/THUMB/Honda_2_t.jpg'

import KubFive from '../img/PROJECTS/5 Kub/WEB/Kub_main.jpg'
import KubFive_preview from '../img/PROJECTS/5 Kub/WEB/Kub_main_preview.jpg'
import KubFive_thumb from '../img/PROJECTS/5 Kub/THUMB/Kub_main_t.jpg'
import five1 from '../img/PROJECTS/5 Kub/WEB/Kub_1.jpg'
import five1_thumb from '../img/PROJECTS/5 Kub/THUMB/Kub_1_t.jpg'
import five2 from '../img/PROJECTS/5 Kub/WEB/Kub_2.jpg'
import five2_thumb from '../img/PROJECTS/5 Kub/THUMB/Kub_2_t.jpg'

import AirplaneSix from '../img/PROJECTS/6 Самолет/WEB/Airplane_main.jpg'
import AirplaneSix_preview from '../img/PROJECTS/6 Самолет/WEB/Airplane_main_preview.jpg'
import AirplaneSix_thumb from '../img/PROJECTS/6 Самолет/THUMB/Airplane_main_t.jpg'
import six1 from '../img/PROJECTS/6 Самолет/WEB/Airplane_1.jpg'
import six1_thumb from '../img/PROJECTS/6 Самолет/THUMB/Airplane_1_t.jpg'

import UrbanColorsSeven from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/WEB/UrbanColors_main.jpg'
import UrbanColorsSeven_preview from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/WEB/UrbanColors_main_preview.jpg'
import seven1 from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/WEB/UrbanColors_1.jpg'
import seven2 from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/WEB/UrbanColors_2.jpg'
import seven3 from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/WEB/UrbanColors_3.jpg'
import seven4 from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/WEB/UrbanColors_4.jpg'
import seven5 from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/WEB/UrbanColors_5.jpg'
import seven6 from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/WEB/UrbanColors_6.jpg'
import seven7 from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/WEB/UrbanColors_7.jpg'

import UrbanColorsSeven_thumb from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/THUMB/UrbanColors_main_t.jpg'
import seven1_thumb from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/THUMB/UrbanColors_1_t.jpg'
import seven2_thumb from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/THUMB/UrbanColors_2_t.jpg'
import seven3_thumb from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/THUMB/UrbanColors_3_t.jpg'
import seven4_thumb from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/THUMB/UrbanColors_4_t.jpg'
import seven5_thumb from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/THUMB/UrbanColors_5_t.jpg'
import seven6_thumb from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/THUMB/UrbanColors_6_t.jpg'
import seven7_thumb from '../img/PROJECTS/7 Краски города Илья_Пока тут пусто, ждем плодов/THUMB/UrbanColors_7_t.jpg'

import SnowLeopardEigth from '../img/PROJECTS/8 Ирбис/WEB/SnowLeopard_main.jpg'
import SnowLeopardEigth_preview from '../img/PROJECTS/8 Ирбис/WEB/SnowLeopard_main_preview.jpg'
import eigth1 from '../img/PROJECTS/8 Ирбис/WEB/SnowLeopard_1.jpg'
import eigth2 from '../img/PROJECTS/8 Ирбис/WEB/SnowLeopard_2.jpg'
import eigth3 from '../img/PROJECTS/8 Ирбис/WEB/SnowLeopard_3.jpg'
import eigth4 from '../img/PROJECTS/8 Ирбис/WEB/SnowLeopard_4.jpg'
import SnowLeopardEigth_thumb from '../img/PROJECTS/8 Ирбис/THUMB/SnowLeopard_main_t.jpg'
import eigth1_thumb from '../img/PROJECTS/8 Ирбис/THUMB/SnowLeopard_1_t.jpg'
import eigth2_thumb from '../img/PROJECTS/8 Ирбис/THUMB/SnowLeopard_2_t.jpg'
import eigth3_thumb from '../img/PROJECTS/8 Ирбис/THUMB/SnowLeopard_3_t.jpg'
import eigth4_thumb from '../img/PROJECTS/8 Ирбис/THUMB/SnowLeopard_4_t.jpg'

import HotelNikitinNine from '../img/PROJECTS/9 Hotel Nikitin/WEB/HotelNikitin_main.jpg'
import HotelNikitinNine_preview from '../img/PROJECTS/9 Hotel Nikitin/WEB/HotelNikitin_main_preview.jpg'
import nine1 from '../img/PROJECTS/9 Hotel Nikitin/WEB/HotelNikitin_1.jpg'

import HotelNikitinNine_thumb from '../img/PROJECTS/9 Hotel Nikitin/THUMB/HotelNikitin_main_t.jpg'
import nine1_thumb from '../img/PROJECTS/9 Hotel Nikitin/THUMB/HotelNikitin_1_t.jpg'

import CarTen from '../img/PROJECTS/10 Car/WEB/Car_main.jpg'
import CarTen_preview from '../img/PROJECTS/10 Car/WEB/Car_main_preview.jpg'
import ten0 from '../img/PROJECTS/10 Car/WEB/Car_0.jpg'
import ten1 from '../img/PROJECTS/10 Car/WEB/Car_1.jpg'
import ten2 from '../img/PROJECTS/10 Car/WEB/Car_2.jpg'
import ten3 from '../img/PROJECTS/10 Car/WEB/Car_3.jpg'
import ten4 from '../img/PROJECTS/10 Car/WEB/Car_4.jpg'
import ten5 from '../img/PROJECTS/10 Car/WEB/Car_5.jpg'
import ten6 from '../img/PROJECTS/10 Car/WEB/Car_6.jpg'
import CarTen_thumb from '../img/PROJECTS/10 Car/THUMB/Car_main_t.jpg'
import ten0_thumb from '../img/PROJECTS/10 Car/THUMB/Car_0_t.jpg'
import ten1_thumb from '../img/PROJECTS/10 Car/THUMB/Car_1_t.jpg'
import ten2_thumb from '../img/PROJECTS/10 Car/THUMB/Car_2_t.jpg'
import ten3_thumb from '../img/PROJECTS/10 Car/THUMB/Car_3_t.jpg'
import ten4_thumb from '../img/PROJECTS/10 Car/THUMB/Car_4_t.jpg'
import ten5_thumb from '../img/PROJECTS/10 Car/THUMB/Car_5_t.jpg'
import ten6_thumb from '../img/PROJECTS/10 Car/THUMB/Car_6_t.jpg'

import AstronEleven from '../img/PROJECTS/11 Astron Cycle/WEB/Astron_main.jpg'
import AstronEleven_preview from '../img/PROJECTS/11 Astron Cycle/WEB/Astron_main_preview.jpg'
import eleven1 from '../img/PROJECTS/11 Astron Cycle/WEB/Astron_1.jpg'
import eleven2 from '../img/PROJECTS/11 Astron Cycle/WEB/Astron_2.jpg'
import eleven3 from '../img/PROJECTS/11 Astron Cycle/WEB/Astron_3.jpg'
import eleven4 from '../img/PROJECTS/11 Astron Cycle/WEB/Astron_4.jpg'
import eleven5 from '../img/PROJECTS/11 Astron Cycle/WEB/Astron_5.jpg'
import eleven6 from '../img/PROJECTS/11 Astron Cycle/WEB/Astron_6.jpg'

import FridaTwelve from '../img/PROJECTS/12 Frida Kahlo/WEB/Frida_main.jpg'
import FridaTwelve_preview from '../img/PROJECTS/12 Frida Kahlo/WEB/Frida_main_preview.jpg'
import twelve1 from '../img/PROJECTS/12 Frida Kahlo/WEB/Frida_1.jpg'
import twelve2 from '../img/PROJECTS/12 Frida Kahlo/WEB/Frida_2.jpg'

import JellyfishThirteen from '../img/PROJECTS/13 Медуза/WEB/Jellyfish_main.jpg'
import JellyfishThirteen_preview from '../img/PROJECTS/13 Медуза/WEB/Jellyfish_main_preview.jpg'
import thirteen1 from '../img/PROJECTS/13 Медуза/WEB/Jellyfish_1.jpg'
import thirteen2 from '../img/PROJECTS/13 Медуза/WEB/Jellyfish_2.jpg'
import thirteen3 from '../img/PROJECTS/13 Медуза/WEB/Jellyfish_3.jpg'
import thirteen4 from '../img/PROJECTS/13 Медуза/WEB/Jellyfish_4.jpg'
import thirteen5 from '../img/PROJECTS/13 Медуза/WEB/Jellyfish_5.jpg'
import thirteen6 from '../img/PROJECTS/13 Медуза/WEB/Jellyfish_6.jpg'
import thirteen7 from '../img/PROJECTS/13 Медуза/WEB/Jellyfish_7.jpg'

import VKFestFourteen from '../img/PROJECTS/14 VKFest/WEB/VKFest_main.jpg'
import VKFestFourteen_preview from '../img/PROJECTS/14 VKFest/WEB/VKFest_main_preview.jpg'
import fourteen1 from '../img/PROJECTS/14 VKFest/WEB/VKFest_1.jpg'
import fourteen2 from '../img/PROJECTS/14 VKFest/WEB/VKFest_2.jpg'
import fourteen3 from '../img/PROJECTS/14 VKFest/WEB/VKFest_3.jpg'
import fourteen4 from '../img/PROJECTS/14 VKFest/WEB/VKFest_4.jpg'

import DeerFifthteen from '../img/PROJECTS/15 Снежногорск/WEB/Deer_main.jpg'
import DeerFifthteen_preview from '../img/PROJECTS/15 Снежногорск/WEB/Deer_main_preview.jpg'
import fifthteen1 from '../img/PROJECTS/15 Снежногорск/WEB/Deer_1.jpg'
import fifthteen2 from '../img/PROJECTS/15 Снежногорск/WEB/Deer_2.jpg'
import fifthteen3 from '../img/PROJECTS/15 Снежногорск/WEB/Deer_3.jpg'
import fifthteen4 from '../img/PROJECTS/15 Снежногорск/WEB/Deer_4.jpg'
import fifthteen5 from '../img/PROJECTS/15 Снежногорск/WEB/Deer_5.jpg'
import fifthteen6 from '../img/PROJECTS/15 Снежногорск/WEB/Deer_6.jpg'

import FantasySixteen from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_main.jpg'
import FantasySixteen_preview from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_main_preview.jpg'
import sixteen1 from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_1.jpg'
import sixteen2 from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_2.jpg'
import sixteen3 from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_3.jpg'
import sixteen4 from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_4.jpg'
import sixteen5 from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_5.jpg'
import sixteen6 from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_6.jpg'
import sixteen7 from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_7.jpg'
import sixteen8 from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_8.jpg'
import sixteen9 from '../img/PROJECTS/16 Тобольск/WEB/Fantasy_9.jpg'

import NornikelSeventeen from '../img/PROJECTS/17 Норникель/WEB/Nornikel_main.jpg'
import NornikelSeventeen_preview from '../img/PROJECTS/17 Норникель/WEB/Nornikel_main_preview.jpg'
import seventeen1 from '../img/PROJECTS/17 Норникель/WEB/Nornikel_1.jpg'
import seventeen2 from '../img/PROJECTS/17 Норникель/WEB/Nornikel_2.jpg'

import MuseumEightteen from '../img/PROJECTS/18 Музей ИЗО/WEB/Museum_main.jpg'
import MuseumEightteen_preview from '../img/PROJECTS/18 Музей ИЗО/WEB/Museum_main_preview.jpg'
import eightteen1 from '../img/PROJECTS/18 Музей ИЗО/WEB/Museum_1.jpg'
import eightteen2 from '../img/PROJECTS/18 Музей ИЗО/WEB/Museum_2.jpg'

import SportNineteen from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_main.jpg'
import SportNineteen_preview from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_main_preview.jpg'
import nineteen1 from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_1.jpg'
import nineteen2 from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_2.jpg'
import nineteen3 from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_3.jpg'
import nineteen4 from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_4.jpg'
import nineteen5 from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_5.jpg'
import nineteen6 from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_6.jpg'
import nineteen7 from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_7.jpg'
import nineteen8 from '../img/PROJECTS/19 Спорткомплекс/WEB/Sport_8.jpg'

import OfficeTwenty from '../img/PROJECTS/20 Офис лампочка/WEB/Office_main.jpg'
import OfficeTwenty_preview from '../img/PROJECTS/20 Офис лампочка/WEB/Office_main_preview.jpg'
import twenty1 from '../img/PROJECTS/20 Офис лампочка/WEB/Office_1.jpg'
import twenty2 from '../img/PROJECTS/20 Офис лампочка/WEB/Office_2.jpg'
import twenty3 from '../img/PROJECTS/20 Офис лампочка/WEB/Office_3.jpg'
import twenty4 from '../img/PROJECTS/20 Офис лампочка/WEB/Office_4.jpg'
import twenty5 from '../img/PROJECTS/20 Офис лампочка/WEB/Office_5.jpg'
import twenty6 from '../img/PROJECTS/20 Офис лампочка/WEB/Office_6.jpg'
import twenty7 from '../img/PROJECTS/20 Офис лампочка/WEB/Office_7.jpg'
import twenty8 from '../img/PROJECTS/20 Офис лампочка/WEB/Office_8.jpg'



import AstronEleven_thumb from '../img/PROJECTS/11 Astron Cycle/THUMB/Astron_main_t.jpg'
import eleven1_thumb from '../img/PROJECTS/11 Astron Cycle/THUMB/Astron_1_t.jpg'
import eleven2_thumb from '../img/PROJECTS/11 Astron Cycle/THUMB/Astron_2_t.jpg'
import eleven3_thumb from '../img/PROJECTS/11 Astron Cycle/THUMB/Astron_3_t.jpg'
import eleven4_thumb from '../img/PROJECTS/11 Astron Cycle/THUMB/Astron_4_t.jpg'
import eleven5_thumb from '../img/PROJECTS/11 Astron Cycle/THUMB/Astron_5_t.jpg'
import eleven6_thumb from '../img/PROJECTS/11 Astron Cycle/THUMB/Astron_6_t.jpg'

import FridaTwelve_thumb from '../img/PROJECTS/12 Frida Kahlo/THUMB/Frida_main_t.jpg'
import twelve1_thumb from '../img/PROJECTS/12 Frida Kahlo/THUMB/Frida_1_t.jpg'
import twelve2_thumb from '../img/PROJECTS/12 Frida Kahlo/THUMB/Frida_2_t.jpg'

import JellyfishThirteen_thumb from '../img/PROJECTS/13 Медуза/THUMB/Jellyfish_main_t.jpg'
import thirteen1_thumb from '../img/PROJECTS/13 Медуза/THUMB/Jellyfish_1_t.jpg'
import thirteen2_thumb from '../img/PROJECTS/13 Медуза/THUMB/Jellyfish_2_t.jpg'
import thirteen3_thumb from '../img/PROJECTS/13 Медуза/THUMB/Jellyfish_3_t.jpg'
import thirteen4_thumb from '../img/PROJECTS/13 Медуза/THUMB/Jellyfish_4_t.jpg'
import thirteen5_thumb from '../img/PROJECTS/13 Медуза/THUMB/Jellyfish_5_t.jpg'
import thirteen6_thumb from '../img/PROJECTS/13 Медуза/THUMB/Jellyfish_6_t.jpg'
import thirteen7_thumb from '../img/PROJECTS/13 Медуза/THUMB/Jellyfish_7_t.jpg'

import VKFestFourteen_thumb from '../img/PROJECTS/14 VKFest/THUMB/VKFest_main_t.jpg'
import fourteen1_thumb from '../img/PROJECTS/14 VKFest/THUMB/VKFest_1_t.jpg'
import fourteen2_thumb from '../img/PROJECTS/14 VKFest/THUMB/VKFest_2_t.jpg'
import fourteen3_thumb from '../img/PROJECTS/14 VKFest/THUMB/VKFest_3_t.jpg'
import fourteen4_thumb from '../img/PROJECTS/14 VKFest/THUMB/VKFest_4_t.jpg'

import DeerFifthteen_thumb from '../img/PROJECTS/15 Снежногорск/THUMB/Deer_main_t.jpg'
import fifthteen1_thumb from '../img/PROJECTS/15 Снежногорск/THUMB/Deer_1_t.jpg'
import fifthteen2_thumb from '../img/PROJECTS/15 Снежногорск/THUMB/Deer_2_t.jpg'
import fifthteen3_thumb from '../img/PROJECTS/15 Снежногорск/THUMB/Deer_3_t.jpg'
import fifthteen4_thumb from '../img/PROJECTS/15 Снежногорск/THUMB/Deer_4_t.jpg'
import fifthteen5_thumb from '../img/PROJECTS/15 Снежногорск/THUMB/Deer_5_t.jpg'
import fifthteen6_thumb from '../img/PROJECTS/15 Снежногорск/THUMB/Deer_6_t.jpg'

import FantasySixteen_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_main_t.jpg'
import sixteen1_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_1_t.jpg'
import sixteen2_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_2_t.jpg'
import sixteen3_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_3_t.jpg'
import sixteen4_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_4_t.jpg'
import sixteen5_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_5_t.jpg'
import sixteen6_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_6_t.jpg'
import sixteen7_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_7_t.jpg'
import sixteen8_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_8_t.jpg'
import sixteen9_thumb from '../img/PROJECTS/16 Тобольск/THUMB/Fantasy_9_t.jpg'

import NornikelSeventeen_thumb from '../img/PROJECTS/17 Норникель/THUMB/Nornikel_main_t.jpg'
import seventeen1_thumb from '../img/PROJECTS/17 Норникель/THUMB/Nornikel_1_t.jpg'
import seventeen2_thumb from '../img/PROJECTS/17 Норникель/THUMB/Nornikel_2_or-this_t.jpg'

import MuseumEightteen_thumb from '../img/PROJECTS/18 Музей ИЗО/THUMB/Museum_main_t.jpg'
import eightteen1_thumb from '../img/PROJECTS/18 Музей ИЗО/THUMB/Museum_1_t.jpg'
import eightteen2_thumb from '../img/PROJECTS/18 Музей ИЗО/THUMB/Museum_2_t.jpg'

import SportNineteen_thumb from '../img/PROJECTS/19 Спорткомплекс/THUMB/Sport_main_t.jpg'
import nineteen1_thumb from '../img/PROJECTS/19 Спорткомплекс/THUMB/Sport_1_t.jpg'
import nineteen2_thumb from '../img/PROJECTS/19 Спорткомплекс/THUMB/Sport_2_t.jpg'
import nineteen3_thumb from '../img/PROJECTS/19 Спорткомплекс/THUMB/Sport_3_t.jpg'
import nineteen4_thumb from '../img/PROJECTS/19 Спорткомплекс/THUMB/Sport_4_t.jpg'
import nineteen5_thumb from '../img/PROJECTS/19 Спорткомплекс/THUMB/Sport_5_t.jpg'
import nineteen6_thumb from '../img/PROJECTS/19 Спорткомплекс/THUMB/Sport_6_t.jpg'
import nineteen7_thumb from '../img/PROJECTS/19 Спорткомплекс/THUMB/Sport_7_t.jpg'
import nineteen8_thumb from '../img/PROJECTS/19 Спорткомплекс/THUMB/Sport_8_t.jpg'

import OfficeTwenty_thumb from '../img/PROJECTS/20 Офис лампочка/THUMB/Office_main_t.jpg'
import twenty1_thumb from '../img/PROJECTS/20 Офис лампочка/THUMB/Office_1_t.jpg'
import twenty2_thumb from '../img/PROJECTS/20 Офис лампочка/THUMB/Office_2_t.jpg'
import twenty3_thumb from '../img/PROJECTS/20 Офис лампочка/THUMB/Office_3_t.jpg'
import twenty4_thumb from '../img/PROJECTS/20 Офис лампочка/THUMB/Office_4_t.jpg'
import twenty5_thumb from '../img/PROJECTS/20 Офис лампочка/THUMB/Office_5_t.jpg'
import twenty6_thumb from '../img/PROJECTS/20 Офис лампочка/THUMB/Office_6_t.jpg'
import twenty7_thumb from '../img/PROJECTS/20 Офис лампочка/THUMB/Office_7_t.jpg'
import twenty8_thumb from '../img/PROJECTS/20 Офис лампочка/THUMB/Office_8_t.jpg'

//import twentyone from '../img/PROJECTS/21 Офис лампочка/WEB/IMG_8595.jpg'

import MilitaryNurseTwenty_preview from '../img/PROJECTS/22 Военные медсестры/WEB/Military-Nurse_main_preview.jpg'
import MilitaryNurseTwentytwo from '../img/PROJECTS/22 Военные медсестры/WEB/Military-Nurse_1.jpg'
import twentytwo1 from '../img/PROJECTS/22 Военные медсестры/WEB/Military-Nurse_2.jpg'

import MilitaryNurseTwentytwo_thumb from '../img/PROJECTS/22 Военные медсестры/THUMB/Military-Nurse_main_t.jpg'
import twentytwo1_thumb from '../img/PROJECTS/22 Военные медсестры/THUMB/Military-Nurse_1_t.jpg'
import twentytwo2_thumb from '../img/PROJECTS/22 Военные медсестры/THUMB/Military-Nurse_2_t.jpg'

import BigichevKazan_thumb from '../img/PROJECTS/Bigichev Kazan/Bigichev_main_preview.jpg'
import BigichevKazan_main from '../img/PROJECTS/Bigichev Kazan/Bigichev_main.jpg'
import BigichevKazan_main_thumb from '../img/PROJECTS/Bigichev Kazan/Bigichev_main_t.jpg'
import BigichevKazan1 from '../img/PROJECTS/Bigichev Kazan/Bigichev_1.jpg'
import BigichevKazan1_thumb from '../img/PROJECTS/Bigichev Kazan/Bigichev_1_t.jpg'
import BigichevKazan2 from '../img/PROJECTS/Bigichev Kazan/Bigichev_2.jpg'
import BigichevKazan2_thumb from '../img/PROJECTS/Bigichev Kazan/Bigichev_2_t.jpg'

import Colizeum_thumb from '../img/PROJECTS/Colizeum/Colizeum_main_preview.jpg'
import Colizeum_main from '../img/PROJECTS/Colizeum/Colizeum_1.jpg'
import Colizeum_main_thumb from '../img/PROJECTS/Colizeum/Colizeum_1_t.jpg'
import Colizeum2 from '../img/PROJECTS/Colizeum/Colizeum_2.jpg'
import Colizeum2_thumb from '../img/PROJECTS/Colizeum/Colizeum_2_t.jpg'
import Colizeum3 from '../img/PROJECTS/Colizeum/Colizeum_3.jpg'
import Colizeum3_thumb from '../img/PROJECTS/Colizeum/Colizeum_3_t.jpg'
import Colizeum4 from '../img/PROJECTS/Colizeum/Colizeum_4.jpg'
import Colizeum4_thumb from '../img/PROJECTS/Colizeum/Colizeum_4_t.jpg'
import Colizeum5 from '../img/PROJECTS/Colizeum/Colizeum_5.jpg'
import Colizeum5_thumb from '../img/PROJECTS/Colizeum/Colizeum_5_t.jpg'

import Pampalche_thumb from '../img/PROJECTS/Pampalche/Pampalche_main_preview.jpg'
import Pampalche_main from '../img/PROJECTS/Pampalche/Pampalche_1.jpg'
import Pampalche_main_thumb from '../img/PROJECTS/Pampalche/Pampalche_1_t.jpg'
import Pampalche1 from '../img/PROJECTS/Pampalche/Pampalche_1.jpg'
import Pampalche1_thumb from '../img/PROJECTS/Pampalche/Pampalche_1_t.jpg'
import Pampalche2 from '../img/PROJECTS/Pampalche/Pampalche_2.jpg'
import Pampalche2_thumb from '../img/PROJECTS/Pampalche/Pampalche_2_t.jpg'
import Pampalche3 from '../img/PROJECTS/Pampalche/Pampalche_3.jpg'
import Pampalche3_thumb from '../img/PROJECTS/Pampalche/Pampalche_3_t.jpg'
import Pampalche4 from '../img/PROJECTS/Pampalche/Pampalche_4.jpg'
import Pampalche4_thumb from '../img/PROJECTS/Pampalche/Pampalche_4_t.jpg'
import Pampalche5 from '../img/PROJECTS/Pampalche/Pampalche_5.jpg'
import Pampalche5_thumb from '../img/PROJECTS/Pampalche/Pampalche_5_t.jpg'
import Pampalche6 from '../img/PROJECTS/Pampalche/Pampalche_6.jpg'
import Pampalche6_thumb from '../img/PROJECTS/Pampalche/Pampalche_6_t.jpg'

import Steve_Jobs_Allo_Veslo_thumb from '../img/PROJECTS/Steve Jobs Allo Veslo/Steve Jobs_main_preview.jpg'
import Steve_Jobs_Allo_Veslo_main from '../img/PROJECTS/Steve Jobs Allo Veslo/Steve Jobs_1.jpg'
import Steve_Jobs_Allo_Veslo_main_thumb from '../img/PROJECTS/Steve Jobs Allo Veslo/Steve Jobs_1_t.jpg'
import Steve_Jobs_Allo_Veslo1 from '../img/PROJECTS/Steve Jobs Allo Veslo/Steve Jobs_1.jpg'
import Steve_Jobs_Allo_Veslo1_thumb from '../img/PROJECTS/Steve Jobs Allo Veslo/Steve Jobs_1_t.jpg'
import Steve_Jobs_Allo_Veslo2 from '../img/PROJECTS/Steve Jobs Allo Veslo/Steve Jobs_2.jpg'
import Steve_Jobs_Allo_Veslo2_thumb from '../img/PROJECTS/Steve Jobs Allo Veslo/Steve Jobs_2_t.jpg'

import Urban_Colors_Art_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_main_preview.jpg'
import Urban_Colors_Art_main from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_1.jpg'
import Urban_Colors_Art_main_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_1_t.jpg'
import Urban_Colors_Art1 from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_1.jpg'
import Urban_Colors_Art1_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_1_t.jpg'
import Urban_Colors_Art2 from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_2.jpg'
import Urban_Colors_Art2_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_2_t.jpg'
import Urban_Colors_Art3 from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_3.jpg'
import Urban_Colors_Art3_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_3_t.jpg'
import Urban_Colors_Art4 from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_4.jpg'
import Urban_Colors_Art4_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_4_t.jpg'
import Urban_Colors_Art5 from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_5.jpg'
import Urban_Colors_Art5_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_5_t.jpg'
import Urban_Colors_Art6 from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_6.jpg'
import Urban_Colors_Art6_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_6_t.jpg'
import Urban_Colors_Art7 from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_7.jpg'
import Urban_Colors_Art7_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_7_t.jpg'
import Urban_Colors_Art8 from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_8.jpg'
import Urban_Colors_Art8_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_8_t.jpg'
import Urban_Colors_Art9 from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_9.jpg'
import Urban_Colors_Art9_thumb from '../img/PROJECTS/Urban Colors Арт-объекты/Urban Colors Art_9_t.jpg'

import Ramones_thumb from '../img/PROJECTS/Ramones/Ramones_main_p.jpg'
import Ramones_main from '../img/PROJECTS/Ramones/Ramones_main.jpg'
import Ramones_main_thumb from '../img/PROJECTS/Ramones/Ramones_main_t.jpg'
import Ramones1 from '../img/PROJECTS/Ramones/Ramones_1.jpg'
import Ramones1_thumb from '../img/PROJECTS/Ramones/Ramones_1_t.jpg'
import Ramones2 from '../img/PROJECTS/Ramones/Ramones_2.jpg'
import Ramones2_thumb from '../img/PROJECTS/Ramones/Ramones_2_t.jpg'

import Vintage_thumb from '../img/PROJECTS/Vintage/Vintage_main_p.jpg'
import Vintage_main from '../img/PROJECTS/Vintage/Vintage_main.jpg'
import Vintage_main_thumb from '../img/PROJECTS/Vintage/Vintage_main_t.jpg'
import Vintage1 from '../img/PROJECTS/Vintage/Vintage_1.jpg'
import Vintage1_thumb from '../img/PROJECTS/Vintage/Vintage_1_t.jpg'
import Vintage2 from '../img/PROJECTS/Vintage/Vintage_2.jpg'
import Vintage2_thumb from '../img/PROJECTS/Vintage/Vintage_2_t.jpg'
import Vintage3 from '../img/PROJECTS/Vintage/Vintage_3.jpg'
import Vintage3_thumb from '../img/PROJECTS/Vintage/Vintage_3_t.jpg'
import Vintage4 from '../img/PROJECTS/Vintage/Vintage_4.jpg'
import Vintage4_thumb from '../img/PROJECTS/Vintage/Vintage_4_t.jpg'

import Hookah_thumb from '../img/PROJECTS/Hookah/PrivateHookah_main_p.jpg'
import Hookah_main from '../img/PROJECTS/Hookah/PrivateHookah_main.jpg'
import Hookah_main_thumb from '../img/PROJECTS/Hookah/PrivateHookah_main_t.jpg'
import Hookah1 from '../img/PROJECTS/Hookah/PrivateHookah_1.jpg'
import Hookah1_thumb from '../img/PROJECTS/Hookah/PrivateHookah_1_t.jpg'
import Hookah2 from '../img/PROJECTS/Hookah/PrivateHookah_2.jpg'
import Hookah2_thumb from '../img/PROJECTS/Hookah/PrivateHookah_2_t.jpg'
import Hookah3 from '../img/PROJECTS/Hookah/PrivateHookah_3.jpg'
import Hookah3_thumb from '../img/PROJECTS/Hookah/PrivateHookah_3_t.jpg'
import Hookah4 from '../img/PROJECTS/Hookah/PrivateHookah_4.jpg'
import Hookah4_thumb from '../img/PROJECTS/Hookah/PrivateHookah_4_t.jpg'
import Hookah5 from '../img/PROJECTS/Hookah/PrivateHookah_5.jpg'
import Hookah5_thumb from '../img/PROJECTS/Hookah/PrivateHookah_5_t.jpg'

import Car_Calligraphy_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_main_p.jpg'
import Car_Calligraphy_main from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_main.jpg'
import Car_Calligraphy_main_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_main_t.jpg'
import Car_Calligraphy1 from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_1.jpg'
import Car_Calligraphy1_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_1_t.jpg'
import Car_Calligraphy2 from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_2.jpg'
import Car_Calligraphy2_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_2_t.jpg'
import Car_Calligraphy3 from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_3.jpg'
import Car_Calligraphy3_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_3_t.jpg'
import Car_Calligraphy4 from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_4.jpg'
import Car_Calligraphy4_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_4_t.jpg'
import Car_Calligraphy5 from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_5.jpg'
import Car_Calligraphy5_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_5_t.jpg'
import Car_Calligraphy6 from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_6.jpg'
import Car_Calligraphy6_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_6_t.jpg'
import Car_Calligraphy7 from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_7.jpg'
import Car_Calligraphy7_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_7_t.jpg'
import Car_Calligraphy8 from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_8.jpg'
import Car_Calligraphy8_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_8_t.jpg'
import Car_Calligraphy9 from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_9.jpg'
import Car_Calligraphy9_thumb from '../img/PROJECTS/Car Calligraphy/Car_calligraphy_9_t.jpg'

import TheGirlInASwingh_thumb from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_main_p.jpg'
import TheGirlInASwingh_main from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_main.jpg'
import TheGirlInASwingh_main_thumb from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_main_t.jpg'
import TheGirlInASwingh1 from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_1.jpg'
import TheGirlInASwingh1_thumb from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_1_t.jpg'
import TheGirlInASwingh2 from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_2.jpg'
import TheGirlInASwingh2_thumb from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_2_t.jpg'
import TheGirlInASwingh3 from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_3.jpg'
import TheGirlInASwingh3_thumb from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_3_t.jpg'
import TheGirlInASwingh4 from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_4.jpg'
import TheGirlInASwingh4_thumb from '../img/PROJECTS/The Girl in a Swing/The Girl in a Swing_4_t.jpg'

import ArtworkForElizarCompany_thumb from '../img/PROJECTS/Artwork for Elizar Company/Elizar_main_p.jpg'
import ArtworkForElizarCompany_main from '../img/PROJECTS/Artwork for Elizar Company/Elizar_main.jpg'
import ArtworkForElizarCompany_main_thumb from '../img/PROJECTS/Artwork for Elizar Company/Elizar_main_t.jpg'
import ArtworkForElizarCompany1 from '../img/PROJECTS/Artwork for Elizar Company/Elizar_1.jpg'
import ArtworkForElizarCompany1_thumb from '../img/PROJECTS/Artwork for Elizar Company/Elizar_1_t.jpg'
import ArtworkForElizarCompany2 from '../img/PROJECTS/Artwork for Elizar Company/Elizar_2.jpg'
import ArtworkForElizarCompany2_thumb from '../img/PROJECTS/Artwork for Elizar Company/Elizar_2_t.jpg'
import ArtworkForElizarCompany3 from '../img/PROJECTS/Artwork for Elizar Company/Elizar_3.jpg'
import ArtworkForElizarCompany3_thumb from '../img/PROJECTS/Artwork for Elizar Company/Elizar_3_t.jpg'

import Streetballcourt_thumb from '../img/PROJECTS/Streetball Court/Streetballcourt_main_p.jpg'
import Streetballcourt_main from '../img/PROJECTS/Streetball Court/Streetballcourt_main.jpg'
import Streetballcourt_main_thumb from '../img/PROJECTS/Streetball Court/Streetballcourt_main_t.jpg'
import Streetballcourt1 from '../img/PROJECTS/Streetball Court/Streetballcourt_1.jpg'
import Streetballcourt1_thumb from '../img/PROJECTS/Streetball Court/Streetballcourt_1_t.jpg'
import Streetballcourt2 from '../img/PROJECTS/Streetball Court/Streetballcourt_2.jpg'
import Streetballcourt2_thumb from '../img/PROJECTS/Streetball Court/Streetballcourt_2_t.jpg'

import creativeschool_thumb from '../img/PROJECTS/Creative School/creativeschool_main_p.jpg'
import creativeschool_main from '../img/PROJECTS/Creative School/creativeschool_main.jpg'
import creativeschool_main_thumb from '../img/PROJECTS/Creative School/creativeschool_main_t.jpg'
import creativeschool1 from '../img/PROJECTS/Creative School/creativeschool_1.jpg'
import creativeschool1_thumb from '../img/PROJECTS/Creative School/creativeschool_1_t.jpg'
import creativeschool2 from '../img/PROJECTS/Creative School/creativeschool_2.jpg'
import creativeschool2_thumb from '../img/PROJECTS/Creative School/creativeschool_2_t.jpg'
import creativeschool3 from '../img/PROJECTS/Creative School/creativeschool_3.jpg'
import creativeschool3_thumb from '../img/PROJECTS/Creative School/creativeschool_3_t.jpg'
import creativeschool4 from '../img/PROJECTS/Creative School/creativeschool_4.jpg'
import creativeschool4_thumb from '../img/PROJECTS/Creative School/creativeschool_4_t.jpg'
import creativeschool5 from '../img/PROJECTS/Creative School/creativeschool_5.jpg'
import creativeschool5_thumb from '../img/PROJECTS/Creative School/creativeschool_5_t.jpg'
import creativeschool6 from '../img/PROJECTS/Creative School/creativeschool_6.jpg'
import creativeschool6_thumb from '../img/PROJECTS/Creative School/creativeschool_6_t.jpg'
import creativeschool7 from '../img/PROJECTS/Creative School/creativeschool_7.jpg'
import creativeschool7_thumb from '../img/PROJECTS/Creative School/creativeschool_7_t.jpg'

import Memories_thumb from '../img/PROJECTS/Memories/Memories_main_p.jpg'
import Memories_main from '../img/PROJECTS/Memories/Memories_main.jpg'
import Memories_main_thumb from '../img/PROJECTS/Memories/Memories_main_t.jpg'
import Memories1 from '../img/PROJECTS/Memories/Memories_1.jpg'
import Memories1_thumb from '../img/PROJECTS/Memories/Memories_1_t.jpg'
import Memories2 from '../img/PROJECTS/Memories/Memories_2.jpg'
import Memories2_thumb from '../img/PROJECTS/Memories/Memories_2_t.jpg'

import Tsoi_thumb from '../img/PROJECTS/Viktor Tsoi/Tsoi_main_p.jpg'
import Tsoi_main from '../img/PROJECTS/Viktor Tsoi/Tsoi_main.jpg'
import Tsoi_main_thumb from '../img/PROJECTS/Viktor Tsoi/Tsoi_main_t.jpg'
import Tsoi1 from '../img/PROJECTS/Viktor Tsoi/Tsoi_1.jpg'
import Tsoi1_thumb from '../img/PROJECTS/Viktor Tsoi/Tsoi_1_t.jpg'

export const photos = [
    {
        src: Memories_thumb,
        priority: 0,
        title: "MEMORIES",
        description:"Public art for the festival FormArt. Yoshkar-Ola, Russia. Street Art.",
        year:"2022",
        projectType:"Exterior",
        images: [
            {
                original:  Memories_main,
                thumbnail: Memories_main_thumb
            },
            {
                original:  Memories1,
                thumbnail: Memories1_thumb
            },
            {
                original:  Memories2,
                thumbnail: Memories2_thumb
            }]
    },
    {
        src: creativeschool_thumb,
        priority: 100,
        title: "CREATIVE SCHOOL",
        description:"Interior Calligraffiti Walls for The School of Creative Industries. Yoshkar-Ola, Russia. Interior Design",
        year:"2022",
        projectType:"Interior",
        images: [
            {
                original:  creativeschool_main,
                thumbnail: creativeschool_main_thumb
            },
            {
                original:  creativeschool1,
                thumbnail: creativeschool1_thumb
            },
            {
                original:  creativeschool2,
                thumbnail: creativeschool2_thumb
            },
            {
                original:  creativeschool3,
                thumbnail: creativeschool3_thumb
            },
            {
                original:  creativeschool4,
                thumbnail: creativeschool4_thumb
            },
            {
                original:  creativeschool5,
                thumbnail: creativeschool5_thumb
            },
            {
                original:  creativeschool6,
                thumbnail: creativeschool6_thumb
            },
            {
                original:  creativeschool7,
                thumbnail: creativeschool7_thumb
            }]
    },
    {
        src: TheGirlInASwingh_thumb,
        priority: 200,
        title: "The Girl in a Swing",
        description:"New project for street art festival FormArt. Yoshkar-Ola. Street Art.",
        year:"2021",
        projectType:"Exterior",
        images: [
            {
                original:  TheGirlInASwingh_main,
                thumbnail: TheGirlInASwingh_main_thumb
            },
            {
                original:  TheGirlInASwingh1,
                thumbnail: TheGirlInASwingh1_thumb
            },
            {
                original:  TheGirlInASwingh2,
                thumbnail: TheGirlInASwingh2_thumb
            },
            {
                original:  TheGirlInASwingh3,
                thumbnail: TheGirlInASwingh3_thumb
            },
            {
                original:  TheGirlInASwingh4,
                thumbnail: TheGirlInASwingh4_thumb
            }]
    },
    {
        src: Pampalche_thumb,
        priority: 300,
        title: "Serebrozubaya Pampalche",
        description:"New handicraft or artistry for the street art project Urban Colors in Yoshkar-Ola, Russia. Street art.",
        year:"2020",
        projectType:"Exterior",
        images: [
            {
                original: Pampalche_main,
                thumbnail: Pampalche_main_thumb
            },
            {
                original: Pampalche1,
                thumbnail: Pampalche1_thumb
            },
            {
                original: Pampalche2,
                thumbnail: Pampalche2_thumb
            },
            {
                original: Pampalche3,
                thumbnail: Pampalche3_thumb
            },
            {
                original: Pampalche4,
                thumbnail: Pampalche4_thumb
            },
            {
                original: Pampalche5,
                thumbnail: Pampalche5_thumb
            },
            {
                original: Pampalche6,
                thumbnail: Pampalche6_thumb
            },]
    },
    {
        src: Streetballcourt_thumb,
        priority: 400,
        title: "Streetball Court",
        description:"Graffiti Streetball. Yoshkar-Ola, Russia.",
        year:"2022",
        projectType:"Exterior",
        images: [
            {
                original:  Streetballcourt_main,
                thumbnail: Streetballcourt_main_thumb
            },
            {
                original:  Streetballcourt1,
                thumbnail: Streetballcourt1_thumb
            },
            {
                original:  Streetballcourt2,
                thumbnail: Streetballcourt2_thumb
            }]
    },
    {
        src: Tsoi_thumb,
        priority: 600,
        title: "VIKTOR TSOI",
        description:"Street Art for Memory Wall of Viktor Tsoi. Sernur, Mari El, Russia.",
        year:"2022",
        projectType:"Exterior",
        images: [
            {
                original:  Tsoi_main,
                thumbnail: Tsoi_main_thumb
            },
            {
                original:  Tsoi1,
                thumbnail: Tsoi1_thumb
            }]
    },
    {
        src: HardmodeOne_preview,
        title: "Cyber Club “Hardmode”",
        description:"Graffiti for the cyber club “Hardmode”. Yoshkar-Ola, Russia. Interior design.",
        year:"2019",
        priority: 700,
        projectType:"Interior",
        images: [{
            original: HardmodeOne,
            thumbnail: HardmodeOne_thumb
        },
        {
            original: one1,
            thumbnail: one1_thumb
        },
        {
            original: one2,
            thumbnail: one2_thumb
        },
        {
            original: one3,
            thumbnail: one3_thumb
        },
        {
            original: one4,
            thumbnail: one4_thumb
        },
        {
            original: one5,
            thumbnail: one5_thumb
        },
        {
            original: one6,
            thumbnail: one6_thumb
        },
        {
            original: one7,
            thumbnail: one7_thumb
        },
        {
            original: one8,
            thumbnail: one8_thumb
        },
        {
            original: one9,
            thumbnail: one9_thumb
        },
        {
            original: hm_neon_m,
            thumbnail: hm_neon_m_thumb
        },
        {
            original: hm_neon_1,
            thumbnail: hm_neon_1_thumb
        },
        {
            original: hm_neon_2,
            thumbnail: hm_neon_2_thumb
        },
        {
            original: hm_neon_3,
            thumbnail: hm_neon_3_thumb
        },
        {
            original: hm_neon_4,
            thumbnail: hm_neon_4_thumb
        },
        {
            original: hm_neon_5,
            thumbnail: hm_neon_5_thumb
        }]
    },

    {
        src: BlackStarTwo_preview,
        priority: 800,
        projectType:"Interior",
        title: "Black Star",
        description:"Graffiti for the barbershop “Black Star”. Yoshkar-Ola, Russia. Interior design.",
        year:"2019",
        images: [{
            original: BlackStarTwo,
            thumbnail: BlackStarTwo_thumb
        },
        {
            original: two1,
            thumbnail: two1_thumb
        },
        {
            original: two2,
            thumbnail: two2_thumb
        },
        {
            original: two3,
            thumbnail: two3_thumb
        },
        {
            original: two4,
            thumbnail: two4_thumb
        },
        {
            original: two5,
            thumbnail: two5_thumb
        },
        {
            original: two6,
            thumbnail: two6_thumb
        },
        {
            original: two7,
            thumbnail: two7_thumb
        }]
    },

    {
        src: CyberLogovoThree_preview,
        priority: 900,
        title: "Cyber Club “Cyber Logovo”",
        projectType:"Interior",
        description:"Graffiti for the cyber club “Cyber Logovo”. Yoshkar-Ola, Russia. Interior design.",
        year:"2019",
        images: [{
            original: CyberLogovoThree,
            thumbnail: CyberLogovoThree_thumb
        },
        {
            original: three1,
            thumbnail: three1_thumb
        },
        {
            original: three2,
            thumbnail: three2_thumb
        },
        {
            original: three3,
            thumbnail: three3_thumb
        },
        {
            original: three4,
            thumbnail: three4_thumb
        },
        {
            original: three5,
            thumbnail: three5_thumb
        },
        {
            original: three6,
            thumbnail: three6_thumb
        },
        {
            original: three7,
            thumbnail: three7_thumb
        },
        {
            original: three8,
            thumbnail: three8_thumb
        }]
    },

    {
        src: HondaFour_preview,
        priority: 1000,
        title: "Honda&Kuzya",
        projectType:"Interior",
        description:"Graffiti art of the cat in the car. Interior design.",
        year:"2019",
        images: [{
            original: HondaFour,
            thumbnail: HondaFour_thumb
        },
        {
            original: four2,
            thumbnail: four2_thumb
        }]
    },

    {
        src: KubFive_preview,
        priority: 1100,
        title: "The Wall for Hookah bar “Kub”",
        projectType:"Interior",
        description:"Graffiti for the hookah bar “Kub”. Yoshkar-Ola, Russia. Interior design.",
        year:"2019",
        images: [{
            original: KubFive,
            thumbnail: KubFive_thumb
        },
        {
            original: five1,
            thumbnail: five1_thumb
        },
        {
            original: five2,
            thumbnail: five2_thumb
        }]
    },
    {
        src: AirplaneSix_preview,
        priority: 1200,
        title: "Airplane",
        description:"Graffiti art of the airplane. Interior design.",
        year:"2018",
        projectType:"Interior",
        images: [{
            original: AirplaneSix,
            thumbnail: AirplaneSix_thumb
        },
        {
            original: six1,
            thumbnail: six1_thumb
        }]
    },
    {
        src: UrbanColorsSeven_preview,
        priority: 1300,
        title: "Street Art Project “Urban Colors”",
        projectType:"Workshop",
        description:"Educational street art project curated by Ilya Yozor since 2019-2020 in Yoshkar-Ola, Russia. Personal art project.",
        year:"2020",
        images: [{
            original: UrbanColorsSeven,
            thumbnail: UrbanColorsSeven_thumb
        },
        {
            original: seven1,
            thumbnail: seven1_thumb
        },
        {
            original: seven2,
            thumbnail: seven2_thumb
        },
        {
            original: seven3,
            thumbnail: seven3_thumb
        },
        {
            original: seven4,
            thumbnail: seven4_thumb
        },
        {
            original: seven5,
            thumbnail: seven5_thumb
        },
        {
            original: seven6,
            thumbnail: seven6_thumb
        },
        {
            original: seven7,
            thumbnail: seven7_thumb
        }]
    },

    {
        src: SnowLeopardEigth_preview,
        priority: 1400,
        title: "Snow Leopard",
        description:"Collaborative project by Russian Geographical Society and Ilya Yzor. Yoshkar-Ola, Russia. Street art.",
        year:"2019",
        projectType:"Exterior",
        images: [{
            original: SnowLeopardEigth,
            thumbnail: SnowLeopardEigth_thumb
        },
        {
            original: eigth1,
            thumbnail: eigth1_thumb
        },
        {
            original: eigth2,
            thumbnail: eigth2_thumb
        },
        {
            original: eigth3,
            thumbnail: eigth3_thumb
        },
        {
            original: eigth4,
            thumbnail: eigth4_thumb
        }]
    },

    {
        src: HotelNikitinNine_preview,
        priority: 1500,
        title: "Nikitin Graffiti",
        projectType:"Exterior",
        description:"Graffiti for the Hotel Nikitin in Yoshkar-Ola. Street art.",
        year:"2019",
        images: [{
            original: HotelNikitinNine,
            thumbnail: HotelNikitinNine_thumb
        },
        {
            original: nine1,
            thumbnail: nine1_thumb
        }]
    },

    {
        src: CarTen_preview,
        priority: 1600,
        title: "Car",
        projectType:"Interior",
        description:"Graffiti art of the car. Interior design.",
        year:"2019",
        images: [{
            original: CarTen,
            thumbnail: CarTen_thumb
        },
        {
            original: ten0,
            thumbnail: ten0_thumb
        },
        {
            original: ten1,
            thumbnail: ten1_thumb
        },
        {
            original: ten2,
            thumbnail: ten2_thumb
        },
        {
            original: ten3,
            thumbnail: ten3_thumb
        }
        ,
        {
            original: ten4,
            thumbnail: ten4_thumb
        },
        {
            original: ten5,
            thumbnail: ten5_thumb
        },
        {
            original: ten6,
            thumbnail: ten6_thumb
        }]
    },

    {
        src: AstronEleven_preview,
        priority: 1700,
        title: "Astron Fitness Club",
        description:"Graffiti art for the fitness club “Astron Fitness”. Yoshkar-Ola, Russia. Interior design.",
        year:"2018",
        projectType:"Interior",
        images: [{
            original: AstronEleven,
            thumbnail: AstronEleven_thumb
        },
        {
            original: eleven1,
            thumbnail: eleven1_thumb
        },
        {
            original: eleven2,
            thumbnail: eleven2_thumb
        },
        {
            original: eleven3,
            thumbnail: eleven3_thumb
        },
        {
            original: eleven4,
            thumbnail: eleven4_thumb
        },
        {
            original: eleven5,
            thumbnail: eleven5_thumb
        },
        {
            original: eleven6,
            thumbnail: eleven6_thumb
        }]
    },

    {
        src: FridaTwelve_preview,
        priority: 1800,
        title: "Frida Kahlo",
        description:"Graffiti portrait “Frida Kahlo” for “Žlutá pumpa” Cafe. Prague, Czech Republic. Interior design.",
        year:"2019",
        projectType:"Interior",
        images: [{
            original: FridaTwelve,
            thumbnail: FridaTwelve_thumb
        },
        {
            original: twelve1,
            thumbnail: twelve1_thumb
        },
        {
            original: twelve2,
            thumbnail: twelve2_thumb
        }]
    },

    {
        src: JellyfishThirteen_preview,
        priority: 1900,
        title: "Street Art “Jellyfish”",
        description:"Street art painted during the Meeting of Styles festival in Wiesbaden, Germany. Street art project.",
        year:"2019",
        projectType:"Exterior",
        images: [{
            original: JellyfishThirteen,
            thumbnail: JellyfishThirteen_thumb
        },
        {
            original: thirteen1,
            thumbnail: thirteen1_thumb
        },
        {
            original: thirteen2,
            thumbnail: thirteen2_thumb
        },
        {
            original: thirteen3,
            thumbnail: thirteen3_thumb
        },
        {
            original: thirteen4,
            thumbnail: thirteen4_thumb
        },
        {
            original: thirteen5,
            thumbnail: thirteen5_thumb
        },
        {
            original: thirteen6,
            thumbnail: thirteen6_thumb
        },
        {
            original: thirteen7,
            thumbnail: thirteen7_thumb
        }]
    },

    {
        src: VKFestFourteen_preview,
        priority: 2000,
        title: "VKFest",
        description:"Art Installation for an open air festival VK Fest, Beauty Zone. Saint Petersburg, Russia. Street art.",
        year:"2018",
        projectType:"Exterior",
        images: [{
            original: VKFestFourteen,
            thumbnail: VKFestFourteen_thumb
        },
        {
            original: fourteen1,
            thumbnail: fourteen1_thumb
        },
        {
            original: fourteen2,
            thumbnail: fourteen2_thumb
        },
        {
            original: fourteen3,
            thumbnail: fourteen3_thumb
        },
        {
            original: fourteen4,
            thumbnail: fourteen4_thumb
        }]
    },

    {
        src: DeerFifthteen_preview,
        priority: 2100,
        title: "Snezhnogorsk",
        description:"Art for the Formula Iskusstva Festival, SIBUR and RASKRASIMVSE. Snezhnogorsk, Russia. Street artwork.",
        year:"2019",
        projectType:"Interior",
        images: [{
            original: DeerFifthteen,
            thumbnail: DeerFifthteen_thumb
        },
        {
            original: fifthteen1,
            thumbnail: fifthteen1_thumb
        },
        {
            original: fifthteen2,
            thumbnail: fifthteen2_thumb
        },
        {
            original: fifthteen3,
            thumbnail: fifthteen3_thumb
        },
        {
            original: fifthteen4,
            thumbnail: fifthteen4_thumb
        },
        {
            original: fifthteen5,
            thumbnail: fifthteen5_thumb
        },
        {
            original: fifthteen6,
            thumbnail: fifthteen6_thumb
        }]
    },

    {
        src: FantasySixteen_preview,
        priority: 2200,
        title: "Fantasy Girl",
        description:"Painting for the festival “Formula khoroshykh del” SIBUR, RASKRASIMVSE companies, Tobolsk, Russia. Street artwork.",
        year:"2018",
        projectType:"Exterior",
        images: [{
            original: FantasySixteen,
            thumbnail: FantasySixteen_thumb
        },
        {
            original: sixteen1,
            thumbnail: sixteen1_thumb
        },
        {
            original: sixteen2,
            thumbnail: sixteen2_thumb
        },
        {
            original: sixteen3,
            thumbnail: sixteen3_thumb
        },
        {
            original: sixteen4,
            thumbnail: sixteen4_thumb
        },
        {
            original: sixteen5,
            thumbnail: sixteen5_thumb
        },
        {
            original: sixteen6,
            thumbnail: sixteen6_thumb
        },{
            original: sixteen7,
            thumbnail: sixteen7_thumb
        },{
            original: sixteen8,
            thumbnail: sixteen8_thumb
        },{
            original: sixteen9,
            thumbnail: sixteen9_thumb
        }]
    },

    {
        src: NornikelSeventeen_preview,
        priority: 2300,
        title: "Nornikel",
        description:"Art for the seaport, NORNIKEL and RASKRASIMVSE. Murmansk, Russia.",
        year:"2019",
        projectType:"Exterior",
        images: [{
            original: NornikelSeventeen,
            thumbnail: NornikelSeventeen_thumb
        },
        {
            original: seventeen1,
            thumbnail: seventeen1_thumb
        },
        {
            original: seventeen2,
            thumbnail: seventeen2_thumb
        }]
    },

    {
        src: MuseumEightteen_preview,
        priority: 2400,
        title: "Fine Art Gallery",
        description:"Collaborative project by Fine Art Gallery and Ilya Yzor. Yoshkar-Ola, Russia. Street art project.",
        year:"2018",
        projectType:"Exterior",
        images: [{
            original: MuseumEightteen,
            thumbnail: MuseumEightteen_thumb
        },
        {
            original: eightteen1,
            thumbnail: eightteen1_thumb
        },
        {
            original: eightteen2,
            thumbnail: eightteen2_thumb
        }]
    },

    {
        src: SportNineteen_preview,
        priority: 2500,
        title: "Sports Complex",
        description:"Graffiti arts for the sports complex. Yoshkar-Ola, Russia. Interior design.",
        year:"2020",
        projectType:"Interior",
        images: [{
            original: SportNineteen,
            thumbnail: SportNineteen_thumb
        },
        {
            original: nineteen1,
            thumbnail: nineteen1_thumb
        },
        {
            original: nineteen2,
            thumbnail: nineteen2_thumb
        },
        {
            original: nineteen3,
            thumbnail: nineteen3_thumb
        },
        {
            original: nineteen4,
            thumbnail: nineteen4_thumb
        },
        {
            original: nineteen5,
            thumbnail: nineteen5_thumb
        },
        {
            original: nineteen6,
            thumbnail: nineteen6_thumb
        },{
            original: nineteen7,
            thumbnail: nineteen7_thumb
        },{
            original: nineteen8,
            thumbnail: nineteen8_thumb
        }]
    },
    {
        src: OfficeTwenty_preview,
        priority: 2600,
        title: "Office",
        description:"Office graffiti. Interior design.",
        year:"2018",
        projectType:"Interior",
        images: [{
            original: OfficeTwenty,
            thumbnail: OfficeTwenty_thumb
        },
        {
            original: twenty1,
            thumbnail: twenty1_thumb
        },
        {
            original: twenty2,
            thumbnail: twenty2_thumb
        },
        {
            original: twenty3,
            thumbnail: twenty3_thumb
        },
        {
            original: twenty4,
            thumbnail: twenty4_thumb
        },
        {
            original: twenty5,
            thumbnail: twenty5_thumb
        },
        {
            original: twenty6,
            thumbnail: twenty6_thumb
        }, {
            original: twenty7,
            thumbnail: twenty7_thumb
        }, {
            original: twenty8,
            thumbnail: twenty8_thumb
        }]
    },
    {
        src: MilitaryNurseTwenty_preview,
        priority: 2700,
        title: "Military Nurser",
        description:"A graffiti representing a military nurse by Ilya Yzor. Yoshkar-Ola, Russia.",
        year:"2018",
        projectType:"Exterior",
        images: [
        {
            original: MilitaryNurseTwentytwo,
            thumbnail: MilitaryNurseTwentytwo_thumb
        },
        {
            original: twentytwo1,
            thumbnail: twentytwo1_thumb
        },]
    },
    {
        src: BigichevKazan_thumb,
        priority: 2800,
        title: "Bigicheva Kazan",
        description:"Collaborative project by Sly Dog, YZOR and Kazan city hall. Kazan, Russia. Art project.",
        year:"2020",
        projectType:"Exterior",
        images: [
        {
            original: BigichevKazan_main,
            thumbnail: BigichevKazan_main_thumb
        },
        {
            original: BigichevKazan1,
            thumbnail: BigichevKazan1_thumb
        },
        {
            original: BigichevKazan2,
            thumbnail: BigichevKazan2_thumb
        },
    ]
    },
    {
        src: Colizeum_thumb,
        priority: 2900,
        title: "Cyber Club “Colizeum”",
        description:"Graffiti for Cyber Club “Colizeum”. Yoshkar-Ola, Russia. Interior design.",
        year:"2020",
        projectType:"Interior",
        images: [
        {
            original: Colizeum_main,
            thumbnail: Colizeum_main_thumb
        },
        {
            original: Colizeum2,
            thumbnail: Colizeum2_thumb
        },
        {
            original: Colizeum3,
            thumbnail: Colizeum3_thumb
        },
        {
            original: Colizeum4,
            thumbnail: Colizeum4_thumb
        },
        {
            original: Colizeum5,
            thumbnail: Colizeum5_thumb
        },]   
    },
    {
        src: ArtworkForElizarCompany_thumb,
        priority: 3000,
        title: "Artwork for Elizar Company",
        description:"Graffiti for European-Asian Trading Company in Yoshkar-Ola. Exteriror Design.",
        year:"2021",
        projectType:"Exterior",
        images: [
            {
                original:  ArtworkForElizarCompany_main,
                thumbnail: ArtworkForElizarCompany_main_thumb
            },
            {
                original:  ArtworkForElizarCompany1,
                thumbnail: ArtworkForElizarCompany1_thumb
            },
            {
                original:  ArtworkForElizarCompany2,
                thumbnail: ArtworkForElizarCompany2_thumb
            },
            {
                original:  ArtworkForElizarCompany3,
                thumbnail: ArtworkForElizarCompany3_thumb
            }]
    },
    {
        src: Steve_Jobs_Allo_Veslo_thumb,
        priority: 3100,
        title: "Steve Jobs Allo Veslo",
        description:"Graffiti portrait for the electronics store “Allo Veslo”. Yoshkar-Ola, Russia. Interior design.",
        year:"2020",
        projectType:"Interior",
        images: [
            {
                original: Steve_Jobs_Allo_Veslo_main,
                thumbnail: Steve_Jobs_Allo_Veslo_main_thumb
            },
            {
                original: Steve_Jobs_Allo_Veslo1,
                thumbnail: Steve_Jobs_Allo_Veslo1_thumb
            },
            {
                original: Steve_Jobs_Allo_Veslo2,
                thumbnail: Steve_Jobs_Allo_Veslo2_thumb
            },
           ]
    },
    {
        src: Urban_Colors_Art_thumb,
        priority: 3200,
        title: "Street Art Project “Urban Colors”",
        description:"7 street arts, 400 m2 painting curated by Ilya Yzor. The biggest educational and street art project in Yoshkar-Ola, Russia. Personal art project.",
        year:"2020",
        projectType:"Exterior",
        images: [
            {
                original: Urban_Colors_Art_main,
                thumbnail: Urban_Colors_Art_main_thumb
            },
            {
                original: Urban_Colors_Art1,
                thumbnail: Urban_Colors_Art1_thumb
            },
            {
                original: Urban_Colors_Art2,
                thumbnail: Urban_Colors_Art2_thumb
            },
            {
                original: Urban_Colors_Art3,
                thumbnail: Urban_Colors_Art3_thumb
            },
            {
                original: Urban_Colors_Art4,
                thumbnail: Urban_Colors_Art4_thumb
            },
            {
                original: Urban_Colors_Art5,
                thumbnail: Urban_Colors_Art5_thumb
            },
            {
                original: Urban_Colors_Art6,
                thumbnail: Urban_Colors_Art6_thumb
            },
            {
                original: Urban_Colors_Art7,
                thumbnail: Urban_Colors_Art7_thumb
            },
            {
                original: Urban_Colors_Art8,
                thumbnail: Urban_Colors_Art8_thumb
            },
            {
                original: Urban_Colors_Art9,
                thumbnail: Urban_Colors_Art9_thumb
            },]
    },
    {
        src:Ramones_thumb,
        priority: 3300,
        title: "RAMONES",
        description:"BLACK AND WHITE GRAFFITI ART. INTERIOR DESIGN.",
        year:"2021",
        projectType:"Interior",
        images: [
            {
                original:Ramones_main,
                thumbnail:Ramones_main_thumb
            },
            {
                original:Ramones1,
                thumbnail:Ramones1_thumb
            },
            {
                original:Ramones2,
                thumbnail:Ramones2_thumb
            }]
    },
    {
        src: Vintage_thumb,
        priority: 3400,
        title: "VINTAGE",
        description:"SPRAY PAINT AND FLOWER ARRANGEMENT FOR THE CAFE “VINTAGE”. INTERIOR DESIGN.",
        year:"2020",
        projectType:"Interior",
        images: [
            {
                original:  Vintage_main,
                thumbnail: Vintage_main_thumb
            },
            {
                original:  Vintage1,
                thumbnail: Vintage1_thumb
            },
            {
                original:  Vintage2,
                thumbnail: Vintage2_thumb
            },
            {
                original:  Vintage3,
                thumbnail: Vintage3_thumb
            },
            {
                original:  Vintage4,
                thumbnail: Vintage4_thumb
            }]
    },
    {
        src: Hookah_thumb,
        priority: 3500,
        title: "THE PRIVATE HOOKAH LOUNGE",
        description:"GRAFFITI IN HOOKAH VIP AREAS. INTERIOR DESIGN.",
        year:"2020",
        projectType:"Interior",
        images: [
            {
                original:  Hookah_main,
                thumbnail: Hookah_main_thumb
            },
            {
                original:  Hookah1,
                thumbnail: Hookah1_thumb
            },
            {
                original:  Hookah2,
                thumbnail: Hookah2_thumb
            },
            {
                original:  Hookah3,
                thumbnail: Hookah3_thumb
            },
            {
                original:  Hookah4,
                thumbnail: Hookah4_thumb
            },
            {
                original:  Hookah5,
                thumbnail: Hookah5_thumb
            }]
    },
    {
        src: Car_Calligraphy_thumb,
        priority: 3600,
        title: "BMW 6 CALLIGRAFFITI",
        description:"CALLIGRAFFITI ON CAR",
        year:"2021",
        projectType:"Exterior",
        images: [
            {
                original:  Car_Calligraphy_main,
                thumbnail: Car_Calligraphy_main_thumb
            },
            {
                original:  Car_Calligraphy1,
                thumbnail: Car_Calligraphy1_thumb
            },
            {
                original:  Car_Calligraphy2,
                thumbnail: Car_Calligraphy2_thumb
            },
            {
                original:  Car_Calligraphy3,
                thumbnail: Car_Calligraphy3_thumb
            },
            {
                original:  Car_Calligraphy4,
                thumbnail: Car_Calligraphy4_thumb
            },
            {
                original:  Car_Calligraphy5,
                thumbnail: Car_Calligraphy5_thumb
            },
            {
                original:  Car_Calligraphy6,
                thumbnail: Car_Calligraphy6_thumb
            },
            {
                original:  Car_Calligraphy7,
                thumbnail: Car_Calligraphy7_thumb
            },
            {
                original:  Car_Calligraphy8,
                thumbnail: Car_Calligraphy8_thumb
            },
            {
                original:  Car_Calligraphy9,
                thumbnail: Car_Calligraphy9_thumb
            }]
    },
    
   
];
