import { Component } from 'react';
import React from 'react';
import './heroAbout.css'


class HeroAbout extends Component {
    render() {
        return (
            <div className="hero-about" >
                
            </div>
        );
    }
}

export default HeroAbout;