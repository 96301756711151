import React from 'react'
import './imageItem.css'
import { Modal, ModalGateway } from 'react-images';
import ModalGallery from '../modalGallery'



export function ImageItem(props: { src: string | undefined; title: string; description: string; year: string; images: { original: string; thumbnail: string;}[]; }) 
{
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);
    const [overlayIsOpen, setOverlayIsOpen] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const openLightbox = () => {
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setViewerIsOpen(false);
    };

    const openOverlayHander = () => {
        setOverlayIsOpen(true);
    };

    const closeOverlayHander = () => {
        setOverlayIsOpen(false);
    };

    return (
        <React.Fragment>
            
            <div className="image-container"
                onMouseEnter={openOverlayHander}
                onMouseLeave={closeOverlayHander}
                onMouseUp={openLightbox}>

                        <img className="image thumb" 
                            src={props.images[0].thumbnail}
                            alt={props.title}
                            style={{ visibility: isLoaded ? "hidden" : "visible" }}
                            />

                        <img 
                            onLoad={() => {
                                setIsLoaded(true);
                            }}
                            className="image full" 
                            style={{ opacity: isLoaded ? 1 : 0 }}
                            src={props.src}
                            alt={props.title}
                            />

                {
                    overlayIsOpen ?
                    <div className="overlay">
                        <h1>\\\{props.title}</h1>
                        <div className="text">
                            <p>{props.description}</p>
                            <p>{props.year}</p>
                        </div>
                    </div>
                    : null
                }
                </div >
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <div>
                                <ModalGallery 
                                    images={props.images} 
                                    thumbnailPosition="right" 
                                    showThumbnails={true} 
                                    showPlayButton={true} 
                                    showFullscreenButton={true} 
                                    onClick = {closeLightbox}/>
                            </div>
                        </Modal>
                    ) : null}
                </ModalGateway>
            </React.Fragment>
    )
    
}
