import * as React from 'react';
import './projectTypeMenu.css'

export function ProjectTypeMenu(props: { OnClick: (typrItem: string) => void; }) 
{
    const [projectTypeMenuList, setprojectTypeMenuList] = React.useState(
        [
            {
                name:"All projects",
                type: "All",
                isSelected: true
            },
            {
                name:"Interior",
                type: "Interior",
                isSelected: false
            },
            {
                name:"Exterior",
                type: "Exterior",
                isSelected: false
            },
            {
                name:"Workshops",
                type: "Workshop",
                isSelected: false
            },
        ]);
    
    const onClickHandler = (typeItem:{name:string, type:string, isSelected:boolean}, indexType: number)=>
    {
        projectTypeMenuList.map((item, index)=>
        {
            index === indexType
            ? item.isSelected=true
            : item.isSelected=false
        })
        setprojectTypeMenuList(projectTypeMenuList)
        props.OnClick(typeItem.type)
    }
    return <React.Fragment>
        {
            projectTypeMenuList.map((typeItem, index)=>
            <div className="projectTypeItem" 
                 key={index}
                 onClick={()=>onClickHandler(typeItem, index)}>
                {
                    index === 0 ? 
                    null :
                    <span className="separatorCharacter"> \ </span>
                }
                <span className={typeItem.isSelected ? 'Selected': ''}>
                    {typeItem.name} 
                </span>
            </div>
            )
        }
    </React.Fragment>
}
