import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../../img/logo.png';


export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm box-shadow " dark>
                    <Container>
                        <NavbarBrand tag={Link} to="/" className="logo text-white ml-4"><img src={logo} width="90px"/></NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2 "/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow mr-4">
                                <NavItem>
                                    <NavLink tag={Link} className="text-white menu-item" to="/">HOME</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-white menu-item" to="/about">ABOUT</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-white menu-item" to="/contact">CONTACT</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
