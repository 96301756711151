import * as React from 'react';
import HeroContact from './heroContact';
import './Contact.css'
import axios from '../../configs/axiosConfig'
import withToast from '../../hoc/withToast'



type MyProps = { toast:any };
type MyState = { Name:string, Email:string, Message:string};

class Contact extends React.Component<MyProps ,MyState> 
{
    constructor(props:any) 
    {
        super(props);
        this.state = 
        {
            Name: '',
            Email: '',
            Message: ''
        }
    }
  
    resetForm()
    {
        this.setState({Name: '', Email: '', Message: ''})
    }

    render() 
    {
        return(<React.Fragment>
                <HeroContact />
                <div className="wrapper-contact">
                    <div className="contact-content">
                        <div className="contact-block left">
                            <ul className="ul-contacts">
                                <li>
                                    <h1 className="">ILYA YZOR</h1>
                                    <hr/>
                                </li>
                                <li>
                                    <a className="text-contacts" href="https://vk.com/ilya_yzor">VK</a>
                                </li>
                                <li>
                                    <a className="text-contacts" href="https://www.facebook.com/ilya.yzor">FACEBOOK</a>
                                </li>
                                <li>
                                    <a className="text-contacts" href="https://www.instagram.com/ilya_yzor">INSTAGRAM</a>
                                </li>
                                <li>
                                    <span className="text-contacts">EMAIL: hello.yzor@gmail.com</span>
                                </li>
                                <li>
                                    <span className="text-contacts">NUMBER: +7&nbsp;999&nbsp;609&nbsp;22&nbsp;33</span>
                                </li>
                            </ul>
                        </div>
                        <div className="contact-block right">
                            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                                <ul className="ul-contacts">
                                    <li>
                                        <h1 className="">Feedback</h1>
                                        <hr/>
                                    </li>
                                    <li>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="text-contacts">Name *</span>
                                            </div>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Your name" aria-label="Your name" aria-describedby="basic-addon1"
                                                    value={this.state.Name} onChange={this.onNameChange.bind(this)}/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="text-contacts">Email *</span>
                                            </div>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Email address" aria-label="Email address" aria-describedby="basic-addon1"
                                                    value={this.state.Email} onChange={this.onEmailChange.bind(this)}/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="input-group-prepend">
                                            <span className="text-contacts">Message *</span>
                                        </div>
                                        <div className="input-group">
                                            <textarea className="form-control" aria-label="Enter message" 
                                                    value={this.state.Message} onChange={this.onMessageChange.bind(this)}/>
                                        </div>
                                    </li>
                                </ul>
                                <button type="submit" className="submit-contact btn btn-outline-dark " >Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )       
    }
    onNameChange(event:any) 
    {
	    this.setState({Name: event.target.value})
    }

    onEmailChange(event:any) 
    {
            this.setState({Email: event.target.value})
    }

    onMessageChange(event:any) 
    {
            this.setState({Message: event.target.value})
    }
  
    handleSubmit(event:any) 
    {
        event.preventDefault();
        axios({
            method: "POST",
            url: "/contact/send",
            data: this.state
        })
            .then((response) => {
                this.props.toast.addToast("Message Sent.", 
                    {appearance: 'success', 
                    autoDismiss: true  });
                this.resetForm()
            })
            .catch((error) => {
                this.props.toast.addToast("Message failed to send.", 
                    {appearance: 'error',
                    autoDismiss: true, 
                    autoDismissTimeout:15000})
            });
    }
}
export default withToast(Contact);