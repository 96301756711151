
export const SelectedProjectsListData = [
    {
        Year: 2012,
        Description: 
        [
            {
                Project: "Started painting graffiti."
            }
        ]
    },
    {
        Year: 2013,
        Description: 
        [
            {
                Project: "Graffiti Style for Zamess. Yoshkar-Ola, Russia."
            }
        ]
    },
    {
        Year: 2014,
        Description: 
        [
            {
                Project: "Military Patriotic Graffiti “The Victory Day”. Yoshkar-Ola, Russia."
            }
        ]
    },
    {
        Year: 2016,
        Description: 
        [
            {
                Project: "Graffiti project “Different Perception” (“Drugoe Vospriyatie”). Tula, Russia."
            }
        ]
    },
    {
        Year: 2017,
        Description: 
        [
            {
                Project: "World War II Graffiti. Yoshkar-Ola, Russia;"
            },
            {
                Project: "Street art “Animal Farm” on Meeting of Styles. Wiesbaden, Germany."
            }
        ]
    },
    {
        Year: 2018,
        Description: 
        [
            {
                Project: "Art Installation for the Beauty Zone on VK Fest. Saint-Petersburg, Russia;"
            },
            {
                Project: "The Wall “Cosmic Girl”. Tobolsk, Russia;"
            },
            {
                Project: "The Mural “Red City” on Fine Art Gallery. Yoshkar-Ola, Russia."
            }
        ]
    },
    {
        Year: 2019,
        Description: 
        [
            {
                Project: "Project “Jellyfish” on Meeting of Styles. Wiesbaden, Germany;"
            },
            {
                Project: "Graffiti “Frida Kahlo” for “Žlutá pumpa” Cafe. Prague, Czech Republic;"
            },
            {
                Project: "The Wall “The North Life”. Snezhnogorsk, Russia;"
            },
            {
                Project: "Cyber Club “Hardmode”. Yoshkar-Ola, Russia;"
            },
            {
                Project: "Graffiti “Snow Leopard” (“Irbis”). Yoshkar-Ola, Russia."
            }
        ]
    },
    {
        Year: 2020,
        Description: 
        [
            {
                Project: "Cyber Club “Colizeum”. Yoshkar-Ola, Russia;"
            },
            {
                Project: "Street art project “Urban Colors” (“Kraski Goroda”). Yoshkar-Ola, Russia;"
            },
            {
                Project: "Street art of a Mari Woman “Serebrozubaya Pampalche”. Yoshkar-Ola, Russia;"
            },
            {
                Project: "The Wall “Dancing’”. Blagoveschensk, Russia."
            }
        ]
    }
]