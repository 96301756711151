import { Component } from 'react';
import React from 'react';
import './heroContact.css'


class HeroContact extends Component {
    render() {
        return (
            <div className="hero-contact" >
            </div>
        );
    }
}

export default HeroContact;