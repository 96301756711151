import { Component } from 'react';
import React from 'react';
import './Hero.css'


class Section extends Component {
    render() {
        return (
            <div className={"background"} >
            </div>
        );
    }
}

export default Section;