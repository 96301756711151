import * as React from 'react';
import { photos } from '../photos';
import { ImageItem } from '../imageItem/imageItem'
import './ProjectsGallery.css'

type MyProps = { projectType:string};
type MyState = { projectList:{
                    src: string;
                    title: string;
                    description: string;
                    year: string;
                    priority: number;
                    projectType: string;
                    images: {
                        original: string;
                        thumbnail: string;
                    }[];
                }[], 
                style: any
                   }
            ;

class ProjectsGallery extends React.Component<MyProps ,MyState> 
{

    constructor(props:any) 
    {
        super(props);
        this.mountStyle = this.mountStyle.bind(this)
        this.unMountStyle = this.unMountStyle.bind(this)
        this.filterProjects = this.filterProjects.bind(this)
        this.state = { 
            projectList :photos,
            style: {
                opacity: 0
              }};
    }

    unMountStyle() { // css for unmount animation
        this.setState({
          style: {
            opacity: 0.5
          }
        })
      }
      
      mountStyle() { // css for mount animation
        this.setState({
          style: {
            opacity: 1,
            transition: 'all 0.5s ease',
          }
        })
      }
      componentDidMount(){
        
        this.filterProjects()
        setTimeout(this.mountStyle, 10) // call the into animation

      }

      filterProjects()
      {
        let photosFiltered = photos.filter((x) => 
        { 
            if(this.props.projectType === "All")
            {
                return x.projectType;
            }
            else
            {
                return x.projectType === this.props.projectType
            }
        })
        let projectList = photosFiltered.sort((a, b)=>a.priority > b.priority ? 1: -1)
        this.setState({projectList})
      }

     
      componentDidUpdate(prevProps:{projectType:string}) {
        // Популярный пример (не забудьте сравнить пропсы):
        
        if (this.props.projectType !== prevProps.projectType) {
            this.unMountStyle()
            this.filterProjects()
            setTimeout(this.mountStyle, 10) // call the into animation
        }
      }
    render() 
    {
        return(<div  style={this.state.style} className="gallery-body">
            {
                this.state.projectList.map((x, index) =>
                <div className="gallery-item" key={index}>
                    <ImageItem key={index}
                               src={x.src}
                               title={x.title}
                               description={x.description}
                               year={x.year}
                               images={x.images}
                    />
                </div>
                )
            }
        </div>
        )
    }
}


export default ProjectsGallery;