import { useToasts } from 'react-toast-notifications'
import * as React from 'react';

export default function withToast(Component:any) 
{
    return function WrappedComponent(props:any) 
    {
        const toastFuncs = useToasts()
        
        return <Component {...props} toast={toastFuncs} />;
    }
}