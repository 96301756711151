import * as React from 'react';
import { connect } from 'react-redux';
import Hero from './Hero';
import './Home.css'
import {ProjectTypeMenu} from '../projectTypeMenu/projectTypeMenu'
import ProjectsGallery from "../ProjectsGallery/ProjectsGallery"



const Home = () => 
{
    const [projectType, setProjectType] = React.useState("All");

    const  projectTypeHandler = (newType:string) =>
    {
        setProjectType(newType);
    };

    return <div>
        <section className="welcomeSection auto">
            <Hero />
            <div className="wrapper">
                <div className="ArtworksType-body">
                    <div className="ArtworksType-item ArtworksType-item-header">
                        <h1>ILYA YZOR ARTWORKS \\\</h1>
                    </div>
                    <div className="ArtworksType-item ArtworksType-item-right">
                        <ProjectTypeMenu OnClick={projectTypeHandler}/>
                    </div>
                </div>
            </div>
        </section>
        
        <div className="wrapper">
            <ProjectsGallery projectType={projectType}/>
        </div>
    </div>
};

export default connect()(Home);