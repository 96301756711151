import * as React from 'react';
import { connect } from 'react-redux';
import HeroAbout from './heroAbout';
import './About.css'
import PublisherList from "./PublisherList/PublisherList"
import SelectedProjectsList from "./SelectedProjectsList/SelectedProjectsList"


const About = () => ( 
    <React.Fragment>
        <HeroAbout />
        <div className="wrapper-about">
            <h1>ABOUT\ \ \ ILYA YZOR</h1> 
            <hr/>
            <div className="about-description">
                <p>Ilya Yzor is a young street artist from Russia started from graffiti painting. Nowadays, he is making street art and taking part in different projects and foreign graffiti festivals.</p>
                <p>Yzor organized an educational street art project Urban Colors in Russia. He is holding workshops and meetings with famous street artists and making new artworks. You may see his in different cities of Russia, Germany, Czech Republic.</p>
                <p>Creative experiments and stylish arts. This all about Yzor.</p>
            </div>
            <SelectedProjectsList/>
            
        
            <PublisherList/>
        </div>
    </React.Fragment>
);

export default connect()(About);