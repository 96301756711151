import * as React from 'react';
import './PublisherList.css'
import {PublisherListData} from "./PublisherListData"


type MyProps = {  };
type MyState = { PublisherListData: { Source: {Img: string, Alt: string}, Description:string, URL:string }[]};

class PublisherList extends React.Component<MyProps ,MyState> 
{
    constructor(props:any) 
    {
        super(props);
        this.state=
            { 
                PublisherListData
            }
    }

    render() 
    {
        return(<React.Fragment>
            <h1 className="media-publications-header">Media Publications</h1>
            <hr/>
             {
                this.state.PublisherListData.map((x, index) =>
                    <div className="media-publications" key={index}>
                        <img className="img-style" src={x.Source.Img} alt={x.Source.Alt} />
                        <a className="p-text" href={x.URL}>{x.Description}</a>
                    </div>
                    )
             }
             </React.Fragment>
        )
    
    }
}

export default PublisherList;